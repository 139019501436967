<template>
  <div>
    <page-content>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="150px"
        style="width: 700px"
      >
        <el-form-item
          label="店铺名称:"
          prop="storeName"
        >
          <el-input
            v-model="ruleForm.storeName"
            size="mini"
            placeholder="请输入店铺名称"
            :disabled="type === 'read'"
            class="w240"
          />
        </el-form-item>
        <el-form-item
          label="客服电话:"
          prop="mobile"
        >
          <el-input
            v-model="ruleForm.mobile"
            size="mini"
            placeholder="请输入客服电话"
            maxlength="11"
            class="w240"
            :disabled="type === 'read'"
          />
        </el-form-item>
        <el-form-item
          label="店铺头像:"
          prop="headPic"
        >
          <LoadImgOss
            v-if="type === 'add' || type === 'edit'"
            :multiple="false"
            :priview="false"
            @onSuccess="handleHeadPic"
          />
          <img
            v-if="ruleForm.headPic"
            :src="ruleForm.headPic"
            alt="店铺头像"
            class="imgStore"
          />
        </el-form-item>
        <el-form-item
          label="店铺招牌"
          prop="signPic"
        >
          <p class="remark">
            店铺招牌背景图片呈渐变显示，请在设计时注意整体效果。<br />
            尺寸为750×580，建议400KB左右，支持类型为jpg和png。
          </p>
          <LoadImgOss
            v-if="type === 'add' || type === 'edit'"
            :multiple="false"
            :priview="false"
            @onSuccess="handleSignPic"
          />
          <img
            v-if="ruleForm.signPic"
            :src="ruleForm.signPic"
            alt="店铺招牌图片"
            class="imgStoreBrand"
          />
        </el-form-item>
        <el-form-item
          label="店主简介"
          prop="storekeeperDesc"
        >
          <el-input
            v-model="ruleForm.storekeeperDesc"
            placeholder="请输入简介"
            :disabled="type === 'read'"
            style="width:200px"
          />
          <p class="remark">
            将展示在“农民说”的农民信息区，建议文字精简并突出优势。
          </p>
        </el-form-item>
        <el-form-item
          label="店主承诺"
          prop="commitment"
        >
          <el-input
            v-model="ruleForm.commitment"
            type="textarea"
            placeholder="请输入承诺语"
            style="width:200px"
            :disabled="type === 'read'"
            maxlength="30"
          />
          <p class="remark">
            仅30个字以内
          </p>
        </el-form-item>
        <el-form-item
          v-if="isShowPerFee === 1"
          label="人均消费金额(¥)"
          prop="perFee"
        >
          <el-input
            v-model="ruleForm.perFee"
            size="default"
            placeholder="请输入人均消费金额"
            style="width: 200px"
          />
          <p class="remark">
            农家乐/餐饮的人均消费金额: 1-100000
          </p>
        </el-form-item>
        <el-form-item v-if="type === 'add' || type === 'edit'">
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </page-content>
  </div>
</template>

<script>
import LoadImgOss from '@/components/common/loadImgOss';

// 校验人均消费金额
const checkPerFee = (rule, value, callback) => {
  // 匹配两位小数 0 0.00 99999.99

  const reg = /^[0-9]+(\.[0-9]{1,2})?$/;

  const reg1 = /^00+/;

  if (
    reg.test(value)
    && !reg1.test(value)
    && value >= 1
    && value <= 100000

  ) {
    callback();
    return;
  }

  callback(new Error('请输入正确的金额'));
};
export default {
  name: 'edit-store',
  components: {
    LoadImgOss,
  },
  props: {
    pageType: {
      type: String,
      default: 'oldpage', // oldpage,newpage
    },
  },

  data() {
    const mobilePass = (rule, value, callback) => {
      const reg = /^[1][0-9]{10}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    };

    return {
      ruleForm: {
        storeName: '',
        mobile: '',
        storekeeperDesc: '',
        commitment: '',
        signPic: '',
        headPic: '',

        // 人均消费金额
        perFee: 0,
      },

      rules: {
        storeName: [
          { required: true, message: '请输入店铺名称', trigger: 'change' },
        ],
        mobile: [
          { required: true, message: '请输入客服电话', trigger: 'change' },
          { validator: mobilePass, trigger: 'change' },
        ],
        storekeeperDesc: [
          { required: true, message: '请输入店铺简介', trigger: 'change' },
        ],
        commitment: [
          { required: true, message: '请输入承诺', trigger: 'change' },
        ],
        headPic: [
          { required: true, message: '请上传店铺头像图片', trigger: 'change' },
        ],
        signPic: [
          { required: true, message: '请输入店铺招牌图片', trigger: 'change' },
        ],
        perFee: [
          { required: true, message: '请输入人均消费金额', trigger: 'change' },
          { validator: checkPerFee, trigger: 'change' },
        ],
      },
      storeName: '', // 店铺名称
      mobile: '', // 手机号
      type: '', // 内容类型
      suppliers_id: '', // 供应商id
      storekeeperDesc: '', // 店铺简介
      commitment: '', // 店主承诺
      data: {
        fileList: [],
        imgCode: [],
      },
      shopSign: {
        url: '',
        imgCode: '',
      },
      headData: {
        fileList: [],
        imgCode: [],
      },
      aptitude: {
      },
      storeImageUrl: '',
      headPicUrl: '',
      // 店铺地址
      siteOptions: {
        value: 'id',
        label: 'name',
        children: 'rows',
      },
      siteValue: [],
      siteArr: [],
      addressDetail: '',

      // 是否显示人均消费金额 0: 不显示  1 显示
      isShowPerFee: 0,
    };
  },

  mounted() {
    const { type, id } = this.$route.query;
    this.type = type;
    if (type === 'edit' || type === 'read') {
      this.getInfo();
    }
  },
  methods: {
    // 获取店铺信息
    getInfo() {
      this.$axios({
        method: 'get',
        url: this.$api.store.view,
        params: {
          storeId: this.$route.query.id,
        },
      })
        .then((res) => {
          if (res.code === 0) {
            if (res.data) {
              this.ruleForm = res.data;
              this.isShowPerFee = boolean(res.data?.isShowPerFee);
            }
          }
        }).catch((error) => {
          console.log(error);
        });
    },
    submitForm(formName) {
      let that = this;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { perFee } = this.ruleForm;
          if (perFee) {
            perFee = Number.parseFloat(perFee).toFixed(2);
          }

          this.$confirm('确定保存店铺信息吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios({
              method: 'post',
              url: this.$route.query.type === 'edit' ? this.$api.store.edit : this.$api.store.add,
              data: {
                ...this.ruleForm,
                perFee,
              },
            }).then((res) => {
              if (res.code === 0) {
                that.$message.success('保存成功');
                localStorage.setItem('defaultStoreId', res.data);
                if (this.pageType === 'newpage') {
                  that.$router.replace('/supplybankAudit?type=add');
                } else {
                  that.$router.go(-1);
                }
              }
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleSignPic(data) {
      this.ruleForm.signPic = data[0].url;
    },
    handleHeadPic(data) {
      this.ruleForm.headPic = data[0].url;
    },
  },
};
</script>
<style lang="scss" scoped>
.remark {
  color: #999;
  &>b {
    color: #FF4949;
  }
}
.imgStore{
  width: 100px;
  height: 100px;
  margin: 10px;
}
.imgStoreBrand{
  width: 350px;
  height: 290px;
  margin: 10px;
}
.imgStoreLicsence{
  width: 500px;
  height: 700px;
  margin: 10px;
}
.store-address {
  display: flex;
  .space {
    display: block;
    width: 20px;
  }
}
</style>
