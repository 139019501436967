var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "page-content",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticStyle: { width: "700px" },
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称:", prop: "storeName" } },
                [
                  _c("el-input", {
                    staticClass: "w240",
                    attrs: {
                      size: "mini",
                      placeholder: "请输入店铺名称",
                      disabled: _vm.type === "read",
                    },
                    model: {
                      value: _vm.ruleForm.storeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "storeName", $$v)
                      },
                      expression: "ruleForm.storeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客服电话:", prop: "mobile" } },
                [
                  _c("el-input", {
                    staticClass: "w240",
                    attrs: {
                      size: "mini",
                      placeholder: "请输入客服电话",
                      maxlength: "11",
                      disabled: _vm.type === "read",
                    },
                    model: {
                      value: _vm.ruleForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "mobile", $$v)
                      },
                      expression: "ruleForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺头像:", prop: "headPic" } },
                [
                  _vm.type === "add" || _vm.type === "edit"
                    ? _c("LoadImgOss", {
                        attrs: { multiple: false, priview: false },
                        on: { onSuccess: _vm.handleHeadPic },
                      })
                    : _vm._e(),
                  _vm.ruleForm.headPic
                    ? _c("img", {
                        staticClass: "imgStore",
                        attrs: { src: _vm.ruleForm.headPic, alt: "店铺头像" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺招牌", prop: "signPic" } },
                [
                  _c("p", { staticClass: "remark" }, [
                    _vm._v(
                      " 店铺招牌背景图片呈渐变显示，请在设计时注意整体效果。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 尺寸为750×580，建议400KB左右，支持类型为jpg和png。 "
                    ),
                  ]),
                  _vm.type === "add" || _vm.type === "edit"
                    ? _c("LoadImgOss", {
                        attrs: { multiple: false, priview: false },
                        on: { onSuccess: _vm.handleSignPic },
                      })
                    : _vm._e(),
                  _vm.ruleForm.signPic
                    ? _c("img", {
                        staticClass: "imgStoreBrand",
                        attrs: {
                          src: _vm.ruleForm.signPic,
                          alt: "店铺招牌图片",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店主简介", prop: "storekeeperDesc" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请输入简介",
                      disabled: _vm.type === "read",
                    },
                    model: {
                      value: _vm.ruleForm.storekeeperDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "storekeeperDesc", $$v)
                      },
                      expression: "ruleForm.storekeeperDesc",
                    },
                  }),
                  _c("p", { staticClass: "remark" }, [
                    _vm._v(
                      " 将展示在“农民说”的农民信息区，建议文字精简并突出优势。 "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店主承诺", prop: "commitment" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入承诺语",
                      disabled: _vm.type === "read",
                      maxlength: "30",
                    },
                    model: {
                      value: _vm.ruleForm.commitment,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "commitment", $$v)
                      },
                      expression: "ruleForm.commitment",
                    },
                  }),
                  _c("p", { staticClass: "remark" }, [
                    _vm._v(" 仅30个字以内 "),
                  ]),
                ],
                1
              ),
              _vm.isShowPerFee === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "人均消费金额(¥)", prop: "perFee" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "default",
                          placeholder: "请输入人均消费金额",
                        },
                        model: {
                          value: _vm.ruleForm.perFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "perFee", $$v)
                          },
                          expression: "ruleForm.perFee",
                        },
                      }),
                      _c("p", { staticClass: "remark" }, [
                        _vm._v(" 农家乐/餐饮的人均消费金额: 1-100000 "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "add" || _vm.type === "edit"
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }